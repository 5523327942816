.footer_a footer {
  background: #fff;
  padding: 70px 60px;
  border-top: 6px solid rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1199px) {
  .footer_a footer {
    padding: 70px 15px;
  }
}

.footer_a .main_logo_social img {
  padding-bottom: 20.5px;
}

.footer_a .list-unstyled a {
  word-break: break-all;
}

.footer_a .main_logo_social p {
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: 33.12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #555;
  padding-bottom: 23.13px;
}

.footer_a .main_logo_social .social_icons {
  display: flex;
  gap: 20px;
}

.footer_a .useful_main_content h6 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  margin-bottom: 38px;
}

.footer_a .useful_main_content ul {
  list-style-type: none;
  padding-left: 0;
}

.footer_a .useful_main_content ul li {
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: 26.63px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #555;
  margin-bottom: 33px;
  cursor: pointer;
}

.footer_a .useful_main_content ul li:last-child {
  margin-bottom: 0;
}
.footer_a .container {
  max-width: 100% !important;
  padding: 0 !important;
}

@media screen and (max-width: 767px) {
  .footer_a footer {
    padding: 20px 15px;
  }
  .footer_a .main_logo_social p,
  .footer_a .useful_main_content ul li {
    font-size: 18px;
  }
  .footer_a .main_logo_social img {
    width: 100px;
  }
  .footer_a .main_logo_social .social_icons img {
    width: auto;
    padding-bottom: 0;
  }
}

 /* common css */
 button {
    padding: 0 32px;
    height: 58px;
    width: "160px",
}

/* button A css */
button.btn_a {
    border: none;
    background: #fff;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #2e2e2e;
    box-shadow: 0px 4px 15px 0px #2E2E2E26;
    border-radius: 30px;
}
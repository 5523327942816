body.customPage {
  background-image: none !important; /* Removes background image */
  background-color: #ffffff; /* Optional: Set a default background color */
  overflow: auto; /* Ensure scrolling is enabled */
  height: auto;
}

.checkout-page {
  width: 100%;
}

.checkout-page .cart_left {
  border: 1px solid #ebebec;
  border-radius: 10px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.cutText {
  text-decoration: line-through;
  color: #929fa5;
}

.tImg {
  width: 72px;
  height: 72px;
  border-radius: 2px;
  object-fit: cover;
  margin-right: 4px;
}

.daysborder {
  border: 1px solid #ebebec;
  border-radius: 6px;
  overflow: hidden;
  /* padding: 7px 10px; */
}


.MuiStack-root {
  overflow: inherit;
  height: 57px;
}

.mealavatar svg {
  display: none;
}
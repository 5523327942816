 /* common css */
 button {
    padding: 0 32px;
    height: 58px;
}

 /* button j css */

 button.btn_j {
    background-color: #6F5C91;
    border: 1px solid #A3D65C;
    box-sizing: border-box;
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding: 0 31px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    border-radius: 100%;
}

button.btn_j::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #A3D65C;
    border-radius: 100%;
    transform: rotate(-11deg);
    z-index: 1;
}

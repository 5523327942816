 /* common css */
 button {
    padding: 0 32px;
    height: 58px;
}

   /* button l css */

   button.btn_l {
    border: none;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: #EE6851;
    color: #fff;
    padding: 0 41px;
}
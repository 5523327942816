.header-mask-wrap .header-mask {
  position: absolute;
  left: 0;
  top: 0;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 10%;
  color: white;
  position: relative;
  z-index: 11;
}

.left .logo {
  height: 40px;
  /* Adjust logo size */
}

nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-list {
  list-style: none;
  display: flex;
}

.nav-list ul {
  margin: 0;
}

.nav-list li {
  margin: 0 15px;
}

.nav-list a {
  text-decoration: none;
  transition: color 0.3s;
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #272727;
}

.nav-list a.active {
  color: #f96221;
}

/* Responsive */
@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: center;
    padding: 20px 10%;
  }

  .nav-list {
    flex-direction: column;
    margin-top: 15px;
  }

  .nav-list li {
    margin: 10px 0;
  }
}

/* .right_btns a {
    font-family: Quicksand;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #272727;
    padding-right: 40px;
    text-decoration: none;
} */

.right_btns button {
  border: none;
  border-radius: 15px 4px;
  height: 60px;
  color: #fff;
  padding: 15px 33px;
  background: #f96221;
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-radius: 40px;
}

.hero_sec_left h6 {
  font-family: Quicksand;
  font-size: 46px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #002a48;
  padding-bottom: 2px;
}

.hero_sec_left h1 {
  font-family: Quicksand;
  font-size: 60px;
  font-weight: 700;
  line-height: 68px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #002a48;
  padding-bottom: 30px;
}

.hero_sec_left {
  padding-left: 112px;
}

.hero_sec_left p {
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #242b33;
  padding-bottom: 35px;
}

.hero_sec_left button {
  padding: 13px 58px;
  color: #fff;
  border: none;
  border-radius: 40px;
  height: 60px;
  background: #f96221;
  font-family: Quicksand;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  box-shadow: 0px 15px 30px 0px #00000026;
}

.form_find_food {
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 20px 40px 0px #e6e6e6;
  border-radius: 16px;
}
.form_find_food .input_wrap {
  width: 60%;
  display: flex;
  align-items: center;
  gap: 12px;
}
.input_wrap input {
  width: 100%;
  border: none;
  outline: none;
}
.input_wrap input::placeholder {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9e9e9e;
}
.Rectangle-soil {
  position: absolute;
  top: 0;
  left: 112px;
}
.footer_pink_mask {
  position: absolute;
  bottom: 0;
  left: 0;
}

a.MuiButtonBase-root.MuiMenuItem-root:hover {
  background-color: #ffe8e0;
  color: black;
  text-decoration: none;
}

a.MuiButtonBase-root.MuiMenuItem-root:hover {
  text-decoration: none;
  color: black;
}

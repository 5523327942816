 /* common css */
 button {
    padding: 0 32px;
    height: 58px;
}
 /* button m css */

 button.btn_m {
    border: none;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: #fff;
    color: #A3D65C;
    padding: 0 5px 0 33px;
    border-radius: 40px;
    display: flex;
    gap: 28px;
    justify-content: space-between;
    align-items: center;
}

button.btn_m img {
    background: #A3D65C;
    padding: 10px;
    border-radius: 100%;
}
/* FOOTER-B CSS */
.footer_b footer {
    background: #F0B325;
    padding: 73px 128px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer_b footer ul {
    list-style-type: none;
    margin-bottom: 0;
    display: flex; /* Ensures gap works similarly to SCSS */
    gap: 20px;
    padding: 0;
}

.footer_b footer ul li {
    font-family: 'Inter', sans-serif;
    font-size: 26px;
    font-weight: 900;
    line-height: 31.47px;
    text-align: left;
    color: #fff;
    cursor: pointer;
}
.footer_b .container{
    max-width: 100% !important;
    padding: 0 !important;
}
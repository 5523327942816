.footer_d footer {
    padding: 119px 130px 40px;
    background: #FFF5EC;
    border-radius: 40px 40px 0 0;
}

.footer_d .register_now_box {
    background: #F96221;
    padding: 32px;
    border-radius: 16px;
    margin-bottom: 40px;
}

.footer_d .register_now_box h6 {
    font-family: Quicksand;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fdfdfd;
}

.footer_d .register_now_box .search_subs_now {
    padding: 12px;
    border-radius: 14px;
    background: #fff;
    display: flex;
    align-items: center;
    gap: 12px;
}

.footer_d .register_now_box .search_subs_now input {
    width: 100%;
    border: 1px solid #99A2A5;
    border-radius: 8px;
    padding: 14px 28px;
}

.footer_d .register_now_box .search_subs_now input::placeholder {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #99A2A5;
}

.footer_d .register_now_box .search_subs_now button {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fdfdfd;
    padding: 12px 24.5px;
    background: #F96221;
    border: none;
    border-radius: 57px;
    width: 163px;
}

.footer_d .ul_menus_social {
    border-bottom: 1px solid #CCD1D2;
    padding-bottom: 40px;
}

.footer_d .ul_menus_social ul {
    gap: 60px;
}

.footer_d .ul_menus_social ul li {
    list-style-type: none;
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #00171F;
    cursor: pointer;
}
.container-fluid{
    padding: 0 !important;
}
 /* common css */
 button {
    padding: 0 32px;
    height: 58px;
}
 /* button b css */
 button.btn_b {
    border: none;
    background: #EE6851;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
    box-shadow: 0px 4px 15px 0px #2E2E2E26;
    border-radius: 30px;
}

.border-left {
    border-left: 1px solid #fff;
}

.pl-3 {
    padding-left: 3px;
}
.footer_c footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F74D4D;
    padding: 30.18px 128px;
}

.footer_c footer ul {
    gap: 38px;
}

.footer_c footer ul li {
    list-style-type: none;
    font-family: Inter;
    font-size: 26px;
    font-weight: 900;
    line-height: 31.47px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
    cursor: pointer;
}
.footer_c .container{
    max-width: 100% !important;
    padding: 0 !important;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 !important;
  background-color: #fff;
  color: white;
}

.paBoth {
  padding: 0 6em !important;
}

.left .logo {
  height: 40px;
  object-fit: contain;
  /* Adjust logo size */
}

nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-list {
  list-style: none;
  display: flex;
}

.nav-list ul {
  margin: 0;
}

.nav-list li {
  margin: 0 15px;
}

.navbar-nav .nav-item {
  margin: 7px;
}

.navbar-nav .nav-item .nav-link.active {
  color: #f96221;
}

.nav-list a,
.navbar-light .navbar-nav .nav-link {
  text-decoration: none;
  transition: color 0.3s;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #272727;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #f96221;
}

.nav-list a.active {
  color: #f96221;
}

/* Responsive */
@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: center;
    padding: 20px 10%;
  }

  .nav-list {
    flex-direction: column;
    margin-top: 15px;
  }

  .nav-list li {
    margin: 10px 0;
  }
}

.right_btns a.btn {
  line-height: 30px;
  text-decoration: none;
  font-size: 19px !important;
}

.right_btns a.btn:hover {
  opacity: 0.6 !important;
}

.navbar-brand img {
  object-fit: contain;
}

.right_btns button,
.right_btns a.btn {
  border: none;
  border-radius: 40px;
  height: 60px;
  color: #fff;
  padding: 15px 33px;
  background: #f96221;
  font-family: Poppins;
  font-size: 17px;
  line-height: initial !important;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.right_btns .btn-check:focus + .btn-primary,
.right_btns .btn-primary:focus {
  background: #f96221;
  border-color: #f96221;
  box-shadow: none;
}
.right_btns button:hover,
.right_btns a.btn:hover {
  background: #f96221;
  border-color: #f96221;
  color: #ffffff;
}

.right_btns button:focus,
.right_btns a.btn:focus {
  box-shadow: none;
}

.hero_sec_left h1 {
  font-family: Inter;
  font-size: 38px;
  font-weight: 800;
  line-height: 55.3px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  padding-bottom: 32px;
}

@media screen and (max-width: 443px) {
  .right_btns {
    font-size: 0px;
    gap: 1em;
  }
  .right_btns button,
  .right_btns a.btn {
    flex-grow: 1;
    padding: 15px 7px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px !important;
  }
}

.hero_sec_left h1 span {
  color: #f96221;
}

.hero_sec_left p {
  font-family: Inter;
  font-size: 23px;
  font-weight: 500;
  line-height: 39.22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #4a4a4a;
  padding-bottom: 32px;
}

.hero_sec_left button {
  padding: 18px 47px;
  color: #fff;
  border: none;
  border-radius: 40px;
  height: 60px;
  background: #f96221;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.hero_main_section {
  height: calc(100vh - 96px);
}

@media (max-width: 1280px) {
  .paBoth {
    padding: 0 1em !important;
  }
  .hero_main_section {
    height: auto;
  }
}

.right_btns.showMobile_dev {
  display: none !important;
}

@media (max-width: 1199px) {
  .right_btns.hideMobile_dev {
    display: none !important;
  }
  .right_btns.showMobile_dev {
    display: block !important;
    margin-left: auto;
    margin-right: 13px;
  }
}

@media (max-width: 767px) {
  .right_btns button,
  .right_btns a.btn {
    padding: 10px 10px !important;
    font-size: 14px !important;
    border-radius: 10px !important;
    line-height: 23px !important;
    height: 45px !important;
  }
}

@media (max-width: 460px) {
  .right_btns.showMobile_dev {
    display: block !important;
    margin-left: auto;
    margin-right: 7px;
  }
  .right_btns button,
  .right_btns a.btn {
    padding: 10px 10px !important;
    font-size: 11px !important;
    border-radius: 10px !important;
    line-height: 23px !important;
    height: 45px !important;
  }
}

@media (max-width: 443px) {
  .right_btns.showMobile_dev {
    display: none !important;
    margin-left: auto;
    margin-right: 7px;
  }
  .right_btns button,
  .right_btns a.btn {
    padding: 10px 10px !important;
    font-size: 11px !important;
    border-radius: 10px !important;
    line-height: 23px !important;
    height: 45px !important;
  }
  .right_btns.hideMobile_dev {
    display: flex !important;
  }
}

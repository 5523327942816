 /* common css */
 button {
    padding: 0 32px;
    height: 58px;
}
 /* button c css */
 button.btn_c {
    border: none;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
    box-shadow: 0px 4px 15px 0px #2E2E2E26;
    background: #6F5C91;

}

 /* common css */
 button {
    padding: 0 32px;
    height: 58px;
}

 /* button h css */
 button.btn_h {
    border: none;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: #A3D65C;
    color: #fff;
    border: 1px solid #A3D65C;
    border-radius: 40px;
}
 /* common css */
 button {
    padding: 0 32px;
    height: 58px;
}
 /* button k css */

 button.btn_k {
    border: none;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: #6F5C91;
    color: #fff;
    border: none;
}
 /* common css */
 button {
    padding: 0 32px;
    height: 58px;
}
 /* button e css */
 button.btn_e {
    border: none;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #EE6851;

    background: transparent;
    border: 1px dashed #EE6851;

}
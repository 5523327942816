@import url('react-date-range/dist/styles.css');

body {
  margin: 0;
  padding: 0%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; /* Hide scrollbars */
  background-image: url('./admin/assets/rumatrix2_01 1.png');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  color: #3f2f5b;
}

* {
  font-family: 'Inter', sans-serif;
}

input:-webkit-autofill,
.MuiInputBase-root:has(> input:-webkit-autofill) {
  background-color: #e8f0fe;
}
.MuiInputBase-root {
  border-radius: 10px;
  background: #ffffff;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 3px !important;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9c9c9c91;
}
.element:hover::-webkit-scrollbar-thumb {
  background: #888;
}
.element:hover::-webkit-scrollbar-thumb {
  background: #888;
}

:root {
  --Old-colors-Line: rgba(255, 255, 255, 0.4 n);
  --Old-colors-Line: rgba(245, 246, 248, 1);
}
.error {
  color: red;
}

.css-co8gkt-MuiTypography-root {
  color: #fff;
}
.css-hyum1k-MuiToolbar-root {
}
header {
  padding: 35px 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.css-y1gt6f span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.css-y1gt6f svg {
  width: 18px;
  height: 17px;
  object-fit: contain;
}
/* .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.css-1hzt12c-MuiButtonBase-root-MuiListItemButton-root
  span {
  color: #f96c2f !important;
  font-weight: 600;
  fill: #f96c2f !important;
}
.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.css-1hzt12c-MuiButtonBase-root-MuiListItemButton-root
  svg {
  fill: #f96c2f !important;
} */
header {
  padding: 50px 0;
}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.selctmeal {
  width: 38%;
}
.selectmealbtn {
  width: 62%;
}

@media (max-width: 991px) {
  .selctmeal,
  .selectmealbtn {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .selectmealbtn {
    margin-top: 18px;
  }
}

@media (max-width: 490px) {
  .selectmealbtn {
    flex-wrap: wrap;
  }
}

.swiper-pagination {
  text-align: left !important;
}

.swiper-pagination-bullet {
  background-color: #ffffff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  width: 27px !important;
  border-radius: 11px !important;
}

/* Slider Style */

.sliderBox {
  padding: 0 10px; /* Adjust the horizontal spacing */
}

.slick-list {
  margin: 0 -10px; /* Adjust this to counteract the padding so edges align */
}

.sliderImg {
  width: 100%;
  border-radius: 27px;
  height: 265px;
  object-fit: cover;
}
.slider-container .slick-dots li button:before {
  font-size: 13px;
}

.slider-container .slick-dots li.slick-active button:before {
  color: #f96221;
}

.slider-container .slick-dots {
  bottom: -39px;
}

.slider-container {
  @media (max-width: 899px) {
    width: 88vw;
  }
}

.category-tabs {
  display: flex;
  justify-content: center;
}

.category-tabsFlex {
  gap: 10px;
  margin-bottom: 30px;
  background: #f1f1f1;
  padding: 8px;
  border-radius: 100px;
}

.category-tabs button {
  padding: 9px 40px;
  border: none;
  background: #f1f1f1;
  border-radius: 100px;
  cursor: pointer;
  font-size: 16px;
  height: auto;
}

.category-tabs button.active {
  background: #f96221;
  color: #fff;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.food-card {
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1);
  padding: 20px 15px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.image-container {
  width: 160px;
  height: 160px;
  margin: 0 auto;
  object-fit: cover;
  margin-bottom: 17px;
}

.image-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.food-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.food-card p {
  font-size: 14px;
  color: #555;
  margin-bottom: 15px;
}

.food-card button {
  border: none;
  color: #f96221;
  height: auto;
}

.food-card button:hover {
  border: transparent;
}

.customTbas {
  padding: 0px 40px;
  @media (max-width: 899px) {
    padding: 0;
    .category-tabs {
      button {
        padding: 9px 20px;
      }
    }
  }
}

button.commonBtn {
  border-radius: 100px;
  height: auto;
  box-shadow: none;
  border-radius: 100px;
  padding: 9px 25px;
  text-transform: capitalize !important;
  background-color: #f96221;
}

button.commonBtn:hover {
  background-color: #f96221;
  opacity: 0.9;
}

.slider-container1 .sliderBox {
  width: 90% !important;
  margin: 5px 10px !important;
  @media (max-width: 767px) {
    width: 100% !important;
    margin: 5px 0 !important;
  }
}
.slider-container1 {
  @media (max-width: 899px) {
    width: 88vw;
  }
}

.conImg {
  width: 100%;
  height: 550px;
  object-fit: contain;
  @media (max-width: 767px) {
    margin-top: -100px;
  }
}

.column-cell div {
  font-family: Outfit !important;
}

.slick-slider .hoverBtn:hover {
  background-color: rgb(249, 98, 33) !important;
}

.slick-slider .hoverBtn:hover svg path {
  fill: #ffffff !important;
}

.MuiTypography-root img {
  max-width: 280px;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list {
  max-height: 300px !important;
}

a.MuiButtonBase-root.MuiMenuItem-root {
  color: black !important;
  text-decoration: none !important;
}

.navbar {
  width: 100%;
}

@media screen and (max-width: 535px) {
  .hoverBtn {
    display: none !important;
  }
}

.category-tabs {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
  display: flex;
  padding: 10px 0;
}

.category-tabsFlex {
  display: flex; /* Align buttons horizontally */
  gap: 10px; /* Space between buttons */
}

.tab-button {
  background-color: #f8f8f8; /* Light gray background */
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  flex-shrink: 0; /* Prevent buttons from shrinking */
  text-align: center;
  white-space: nowrap; /* Keep text in one line */
}

.tab-button.active {
  background-color: #007bff; /* Highlight active tab */
  color: #fff;
  border-color: #007bff;
}

.category-tabs::-webkit-scrollbar {
  height: 8px; /* Scrollbar height */
}

.category-tabs::-webkit-scrollbar-thumb {
  background: #ccc; /* Scrollbar color */
  border-radius: 10px;
}

.category-tabs::-webkit-scrollbar-thumb:hover {
  background: #888; /* Scrollbar hover color */
}

@media (max-width: 768px) {
  .tab-button {
    padding: 8px 15px; /* Smaller buttons on small screens */
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .category-tabs {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }
  .category-tabsFlex {
    margin-bottom: 10px;
    overflow-x: auto;
  }

  .swiper-pagination {
    text-align: center !important;
  }

  .swNews .swiper-slide img {
    width: 100% !important;
    object-fit: cover !important;
    height: 250px !important;
    border-radius: 6px !important;
  }
}

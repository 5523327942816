 /* common css */
 button {
    padding: 0 32px;
    height: 58px;
}

 /* button n css */

 button.btn_n {
    background-color: #6F5C91;
    border: 1px solid #3F3F3F;
    box-sizing: border-box;
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding: 0 41.5px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

button.btn_n::before {
    content: "";
    position: absolute;
    top: -6px;
    left: 6px;
    right: 0;
    bottom: 0;
    border: 1px dashed #3F3F3F;
    z-index: 1;
}
 /* common css */
 button {
    padding: 0 32px;
    height: 58px;
}

  /* button i css */

  button.btn_i {
    display: inline-block;
    background-color: #6F5C91;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    clip-path: polygon(0% 0, 90% 0, 100% 100%, 10% 100%);
    border: none;
    padding: 0 74px;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 10%;
  background-color: #fff;
  color: white;
}

.left .logo {
  height: 40px;
  /* Adjust logo size */
}

nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-list {
  list-style: none;
  display: flex;
}

.nav-list ul {
  margin: 0;
}

.nav-list li {
  margin: 0 15px;
}

.nav-list a {
  text-decoration: none;
  transition: color 0.3s;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #272727;
}

.nav-list a.active {
  color: #f96221;
}

/* Responsive */
@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: center;
    padding: 20px 10%;
  }

  .nav-list {
    flex-direction: column;
    margin-top: 15px;
  }

  .nav-list li {
    margin: 10px 0;
  }
}

/* .right_btns a {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #272727;
    padding-right: 40px;
    text-decoration: none;
} */

.right_btns button {
  border: none;
  border-radius: 40px;
  height: 60px;
  color: #fff;
  padding: 15px 33px;
  background: #f96221;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.hero_sec_left h1 {
  font-family: Inter;
  font-size: 38px;
  font-weight: 800;
  line-height: 55.3px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  padding-bottom: 32px;
}

.hero_sec_left h1 span {
  color: #f96221;
}

.hero_sec_left p {
  font-family: Inter;
  font-size: 23px;
  font-weight: 500;
  line-height: 39.22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #4a4a4a;
  padding-bottom: 32px;
}

.hero_sec_left button {
  padding: 18px 47px;
  color: #fff;
  border: none;
  border-radius: 40px;
  height: 60px;
  background: #f96221;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
